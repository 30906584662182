import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image/withIEPolyfill'
import Layout from '../components/layout'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styles from './blog-post.scss'
import defaultRTRenderOptions from "../../contentful/rich-text-renderer-options"
import Section from "../components/section/section";
import { buildBreadcrumbsItem, authorUrl, postUrl } from '../helpers'

class BlogPostTemplate extends React.Component {

  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const defaultImage = get(this.props, 'data.defaultImage.childImageSharp.fluid')
    const publisherName = get(this.props, 'data.site.siteMetadata.publisher.name')
    const url = postUrl(get(this.props, 'data.contentfulBlogPost'), get(this.props, 'data.site.siteMetadata.siteUrl'))
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const options = {
      ...defaultRTRenderOptions
    }

    let seoComponents = []

    let postTitle = post.title

    if (post.seoTitle) {
      seoComponents.push(<meta name="title" content={ post.seoTitle } />)
      postTitle = post.seoTitle
    }
    if (post.metaDescription) {
      seoComponents.push(<meta name="description" content={ post.metaDescription.childMarkdownRemark.internal.content }  />)
    }
    if (post.canonicalUrl) {
      seoComponents.push(<link rel="canonical" href={ post.canonicalUrl } />)
    }
    if (post.schemaOrgJson) {
      seoComponents.push(<script type="application/ld+json">{ post.schemaOrgJson.internal.content }</script>)
    }

    seoComponents.unshift((<title>{ postTitle }</title>))

    let breadcrumbTitle = post.breadcrumbTitle ? post.breadcrumbTitle : post.title

    const breadcrumbs = [
      buildBreadcrumbsItem(breadcrumbTitle, this.props.location.href)
    ]
    const postH1 = get(post, 'hideTitle', false) ? `` : (<h1>{ post.title }</h1>)
    const byLine = post.author ? (
      <div className="blog-data col-12 col-md-8 mt-4 mb-5 mx-auto text-center">Reviewed by: <a href={authorUrl(post.author)} rel="author" className="blog-author">{post.author.name}, {post.author.title}</a></div>) : ''


    return (
      <Layout breadcrumbs={breadcrumbs} location={this.props.location} containerClassNames={`blog-post mt-5 pb-5 ${post.slug} blog_post-${post.contentful_id}`}>
        <Helmet>
          {seoComponents.map((component) => component)}
        </Helmet>
        <Section className="px-0">
          <div className="row d-block">
            <div className="col-10 col-md-6 mx-auto">
              {postH1}
              {byLine}
              <article className="post-content-container" itemScope itemType="https://schema.org/BlogPosting">
                <meta itemProp="url" content={ url } />
                <link itemProp="mainEntityOfPage" href={ url } />
                <meta itemProp="datePublished" content={ post.createdAt } />
                <meta itemProp="dateModified" content={ post.updatedAt } />
                <meta itemProp="headline" content={ post.title } />
                { publisherName ? (
                  <link itemProp="publisher" href={ `#${publisherName.replace(' ', '')}Org` } />
                ) : ''}
                { post.author ? (
                  <div itemProp="author" itemScope itemType="https://schema.org/Person">
                    <meta itemProp="name" content={ post.author.name } />
                    <meta itemProp="honorificSuffix" content={ post.author.title } />
                  </div>
                ) : '' }
                <div className="post-content" itemProp="articleBody">
                  {documentToReactComponents(post.body.json, options)}
                </div>
                <meta itemProp="image" content={ post.heroImage ? post.heroImage.fixed.src : defaultImage.src } />
              </article>
            </div>
          </div>
        </Section>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        publisher {
          name
        }
      }
    }
    contentfulBlogPost(slug: {eq: $slug}) {
      contentful_id
      title
      slug
      hideTitle
      seoTitle
      breadcrumbTitle
      createdAt
      updatedAt
      author {
        name
        title
      }
      metaDescription {
        childMarkdownRemark {
          internal {
            content
          }
        }
      }
      canonicalUrl
      schemaOrgJson {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM Do, YYYY")
      tags
      body {
        json
      }
  
    }
    defaultImage: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
